<template>
  <a-spin :spinning="globalLoding">
    <div class="mainPage">
      <p class="pageTitle">Remark list</p>
      <div class="pageWrap">
        <a-form-model layout="inline" :model="formInline" @submit.native.prevent>
          <a-form-model-item label="ticket number">
            <a-input v-model="formInline.ticketNo" placeholder="Please enter">
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="Remark content">
            <a-input v-model="formInline.remark" placeholder="Please enter">
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="Processing status">
            <a-select v-model="formInline.status">
              <a-select-option value="all">
                All
              </a-select-option>
              <a-select-option value="0">
                unprocessed
              </a-select-option>
              <a-select-option value="1">
                completed
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="Classification">
            <a-select v-model="formInline.classification" dropdownClassName= 'remark-search-form-option-style'>
              <a-select-option value="all">
                All
              </a-select-option>
              <a-select-option :value="item" v-for="(item, index) in classificationList" :key="index">
                {{item}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" @click="getData">Search</a-button>
          </a-form-model-item>
          <a-form-model-item>
            <a-button class="item" @click="handleExport">Export</a-button>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" @click="batchUpdateRemarkStatus" :disabled="selectedRowKeys.length === 0">Whether it is completed</a-button>
          </a-form-model-item>
        </a-form-model>

        <a-table 
          :data-source="tableData"
          :columns="columns"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          @change="handleChange"
          :loading="loading"
          :rowKey="record => record.id"
          :pagination="{
            defaultCurrent: 1,
            total,
            pageSize,
            current: currentPage,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total) => `total ${total}`,
            pageSizeOptions: ['5', '10', '20', '30', '50']
          }"
          class="mt24">
          <span slot="changeStatus" slot-scope="text, record">
            <a-switch :checked="value(record.status)" @change="onChange($event, record.id)" checked-children="Y(处理完成)" un-checked-children="N"></a-switch>
          </span>
          <span slot="action" slot-scope="text, record">
            <a-button type="link" @click="goDetail(record.ticketId)">View details</a-button>
          </span>
          <div slot="remarkContent" slot-scope="text" class="remarkContent">
            {{text}}
          </div>
        </a-table>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { timeFormatUSA } from '@/utils/utils.js'
import { classificationList } from '../../assets/js/listData'
const columns = [
  {
    title: 'ticket number',
    dataIndex: 'ticketNo',
    key: 'ticketNo',
  },
  {
    title: 'remark content',
    dataIndex: 'remark',
    key: 'remark',
    width: 500,
    scopedSlots: { customRender: 'remarkContent' }
  },
  {
    title: 'Prepared by',
    dataIndex: 'operatorAccount',
    key: 'operatorAccount',
  },
  {
    title: 'Date(UTC-8:00)',
    dataIndex: 'editTime',
    key: 'editTime',
    customRender: (text) => {
      return timeFormatUSA(text) || '-'
    }
  },
  {
    title: 'Whether it is completed',
    dataIndex: 'changeStatus',
    key: 'changeStatus',
    scopedSlots: { customRender: 'changeStatus' },
    width: 150
  },
  {
    title: 'Operation',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' }
  }
]
export default {
  components: {},
  data  () {
    return {
      formInline: {
        ticketNo: '',
        status: 'all',
        classification: 'all',
        remark: ''
      },
      tableData: [],
      selectedRowKeys: [],
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      exportTimer: null,
      globalLoding: false,
      classificationList
    }
  },
  beforeDestroy () { 
    this.handleClearTimer()
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    async batchUpdateRemarkStatus () { 
      if (this.selectedRowKeys.length === 0) return
      const onOk = async () => { 
        await this.$http.post('/platform/admin/ticketAdminManage/v1/batchUpdateRemarkStatus', {
          ids: this.selectedRowKeys
        })
        this.getData()
        this.selectedRowKeys = []
      }
      this.$confirm({
        title: 'Are you sure to turn on the switch?',
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        onOk,
      });
    },
    async handleExport () {
      const { status: initStatus, ticketNo, classification: classify, remark } = this.formInline
      const status = initStatus === 'all' ? null : initStatus
      const classification = classify === 'all' ? null: classify
      const res = await this.$http.post('/platform/admin/ticketAdminManage/v1/exportTicketRemark', { status, ticketNo, remark, classification })
      if (res.code === 0) { 
        this.handleRotation(res.data.id)
      }
    },
    handleRotation (id) {
      this.globalLoding = true
      this.exportTimer = setInterval(async () => {
        try {
          const res = await this.$http.post('/platform/admin/ticketAdminManage/v1/getDownloadTask', { id })
          if (res.data?.status === 2) {
            location.href = res.data.accessUrl
            this.handleClearTimer()
          } else if (res.data?.status === 1) { 
            this.handleClearTimer()
          }
        } catch (error) {
          this.handleClearTimer()
        }
      }, 1000)
    },
    handleClearTimer () {
      this.globalLoding = false
      clearInterval(this.exportTimer)
    },
    handleChange (pagination) {
      this.currentPage = pagination.current
      this.pageSize = pagination.pageSize
      this.getData()
    },
    async getData () {
      this.loading = true
      try {
        const { pageSize, currentPage } = this
        const { status: initStatus, ticketNo,classification: classify, remark } = this.formInline
        const status = initStatus === 'all' ? null : initStatus
        const classification = classify === 'all' ? null: classify
        const res = await this.$http.post('/platform/admin/ticketAdminManage/v1/pageRemark', { pageSize, currentPage, ticketNo, remark, status, classification })
        this.tableData = res.data.ticketRemarkList || []
        this.total = res.data.totalRecord
      } catch (error) {
        //
      } finally { 
        this.loading = false
      }
    },
    goDetail (id) {
      const routeData = this.$router.resolve({path: `/ticket/detail/${id}`})
      window.open(routeData.href, '_blank')
    },
    onChange (value, id) {
      const onOk = async () => { 
        try {
          const status = Number(value)
          const res = await this.$http.post('/platform/admin/ticketAdminManage/v1/updateRemarkStatus', { status, id })
          if (res.code === 0) {
            this.$message.success('Update successful');
            this.getData()
          }
        } catch (error) {
          //
        }
      }

      this.$confirm({
        title: 'Are you sure to turn on the switch?',
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        onOk
      });
    }
  },
  mounted  () {
    this.getData()
  },
  computed: {
    value () { 
      return function (val) { 
        return !!val
      }
    }
  }
}
</script>
<style lang='less'>
  .remark-search-form-option-style{
    width: 410px !important;
  }
</style>

<style lang='less' scoped>
.remarkContent{
  width: 500px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
</style>